import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC_FlAMIMWamJ8Zr_MSfW00mTgR9EPZVPE",
  authDomain: "com-nathandonnelly.firebaseapp.com",
  projectId: "com-nathandonnelly",
  storageBucket: "com-nathandonnelly.appspot.com",
  messagingSenderId: "536520312782",
  appId: "1:536520312782:web:346f866ee5e22dd8484d9f",
  measurementId: "G-VJ4STYBF7Q"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
console.log('App initialized.', app);
export const db = getFirestore();
console.log('Database connected.', db);
export const auth = getAuth();
console.log('Authentication retreived.', auth);
export const storage = getStorage();
console.log('Storage retrieved', storage);